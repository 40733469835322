import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotComponent } from "./forgot.component";

const routes: Routes = [{
    path: 'forgot-password',
    component: ForgotComponent
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class ForgotRoutingModule { }
