import { BriefUserModel } from "./user.models";

export class LoginRequestModel {

    phone: string;
    password: string;
    deviceId: string;
    osType: string;
    deviceToken: string;

    constructor(phone: string, password: string) {
        this.phone = phone;
        this.password = password;
    }
}

export class LoginResponseModel {
    user: BriefUserModel;
    role: string;
    authToken: string;
}