import { Transaction } from '@app/core/models/transaction.models';

export class BriefUserModel {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    image: string;
}

export class UpdateProfileRequestModel {
    firstName: string;
    lastName: string;
    imagePath: string;
    email: string;
}

class Card {
    number: string;
}

export class User {
    id: number;
    email: string;
    phone: string;
    location: string;
    datetime: Date;
    cards: Card[];
    status: number;
    transactions?: Transaction[];

    fullname: string;
}

export class UserModel {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    isActive: boolean;
    isNotificationsEnabled: boolean;
    phone: string;
    updateDate: string;
    updateUnixTimeSeconds: number;
    location: string;
    blockMessage?: string;

    cards?: Card[];
    transactions?: Transaction[];
}