import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService, StorageService } from '@app/core/services';
import { LoginRequestModel } from '@app/core/models/login.models';
import { ValidationModel } from '@app/core/models/app.model';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

    proccessing: boolean = false;

    constructor(private _authService: AuthService, private _storage: StorageService, private _router: Router) { }

    ngOnInit() {
        this._storage.clearLoginData();
    }

    login(evt, phone: string, password: string) {
        evt.preventDefault();
        var loginModel = new LoginRequestModel(phone, password);
        var validResult: ValidationModel = this._authService.validateLoginModel(loginModel);
        this.showErrors(validResult);
        if (!validResult.isValid()) {
            return;
        } 

        this.proccessing = true;
        this._authService.login(loginModel).subscribe(data => {
            this.proccessing = false;
            if (!data.success) {
                validResult.modelError = data.message;
                this.showErrors(validResult);
                return;
            }
            this._storage.setLoginData(data.data);
            this._router.navigate(["/"]);
        });
    }

    private showErrors(validModel: ValidationModel) {
        var $form = $("form.client-form");
        $form.find("div.note > span.text-danger").text("");
        if (!validModel || validModel.isValid()) {
            return;
        }
        if (validModel.modelError) {
            var $elem = $form.find(".form-error");
            if ($elem) {
                $elem.text(validModel.modelError);
            }
        }
        if (validModel.errors && validModel.errors.length > 0) {
            validModel.errors.forEach(value => {
                var selector: string = "[name=" + value.key + "]";
                var $elem = $form.find(selector).parent().next().children();
                if ($elem) {
                    $elem.text(value.message);
                }
            });
        }
    }

}
