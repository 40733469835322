import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from '@app/core/services';

@Component({
    selector: 'app-forgot',
    templateUrl: './forgot.component.html',
    styles: []
})
export class ForgotComponent implements OnInit {
    phoneModel: string = "";
    isResetPassword: boolean = false;

    constructor(private router: Router, private _authService: AuthService) { }

    ngOnInit() {
    }

    submit() {
        $(".note > span").text("");
        this._authService.forgotPassword({ phone: this.phoneModel }).subscribe(data => {
            if (!data.success) {
                $(".note > span").text(data.message);
                return;
            }
            this.isResetPassword = true;
            setTimeout(() => {
                this.router.navigate(['/auth/login']);
            }, 3000);
        });
    }
}
